.custom-position-container {
  /* position: absolute !important; */
  /* align-items: flex-start !important; */
}

.custom-position-popup {
  /* margin: unset !important; */
  /* top: 100px; */
}

.ai-popup > .swal2-icon {
  border: none;
}
.ai-popup > .swal2-actions > .swal2-cancel {
  background-color: transparent;
  border: 1px solid #617182;
  color: #617182;
}
.ai-popup > .swal2-actions > .swal2-cancel:hover {
  background-color: #617182!important;
  border: 1px solid #617182;
  color: white;
}

.fade-in {
  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -o-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    top: 0;
  }
  100% {
    opacity: 1;
    top: 100px;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
    top: 0;
  }
  100% {
    opacity: 1;
    top: 100px;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    top: 0;
  }
  100% {
    opacity: 1;
    top: 100px;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
    top: 0;
  }
  100% {
    opacity: 1;
    top: 100px;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
    top: 0;
  }
  100% {
    opacity: 1;
    top: 100px;
  }
}

.fade-out {
  animation: fadeOut 0.5s;
  -webkit-animation: fadeOut 0.5s;
  -moz-animation: fadeOut 0.5s;
  -o-animation: fadeOut 0.5s;
  -ms-animation: fadeOut 0.5s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    top: 100px;
  }
  100% {
    opacity: 0;
    top: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
    top: 100px;
  }
  100% {
    opacity: 0;
    top: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
    top: 100px;
  }
  100% {
    opacity: 0;
    top: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
    top: 100px;
  }
  100% {
    opacity: 0;
    top: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
    top: 100px;
  }
  100% {
    opacity: 0;
    top: 0;
  }
}
