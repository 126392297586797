.custom-absolute-toast {
  position: absolute !important;
  width: 350px;
}

.custom-fixed-toast {
  position: fixed !important;
  width: 350px;
}

.custom-shadow-toast {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.131) !important;
}
